import WasmController from "react-lib/frameworks/WasmController";

// Utils
import moment from "moment";
import { dateToStringWithoutTimeBE } from "react-lib/utils/dateUtils";
import { utils as XLSXUtils, writeFile as XLSXWriteFile } from "xlsx";

// Common
import getPdfMake from "react-lib/appcon/common/pdfMake";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { SetProperty, combinePdfFiles } from "react-lib/apps/HISV3/common/CommonInterface";

// PDF
import FormAnesMethodGenderStatReport from "../pdfFormAdm/FormAnesMethodGenderStatReport";
import FormAnesMethodCaseStatReport from "../pdfFormAdm/FormAnesMethodCaseStatReport";
import FormAnesAsaStatReport from "../pdfFormAdm/FormAnesAsaStatReport";
import FormAnesDurationStatReport from "../pdfFormAdm/FormAnesDurationStatReport";
import FormAnesSatisfactionStatReport from "../pdfFormAdm/FormAnesSatisfactionStatReport";

// APIs
import PreAnestheticReportList from "issara-sdk/apis/PreAnestheticReportList_apps_ANS";

// Interface
import { State as MainState } from "HIS/MainHISInterface";

export type State = Partial<{
  // Sequence
  AnesthesiaStatReportSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    // searchStatus: string | null; // กรณีใช้ทำ Modal Notification
    anesDataCount: number;
    preAnesData: any[];
    formattedAnesData: any[];
    summarizedReportByMethod: any;
    summarizedRepotrByTeam: any;
    summarizedEmergencyReport: any;
    filteredAnesData: any[];
    filterReport: Partial<{
      filterStartDate: string;
      filterEndDate: string;
      selectedReport: ReportType | null;
    }>;
  }>;
}>;

type PickedState = Partial<Pick<MainState, "buttonLoadCheck" | "django" | "selectedDivision">>;

export type PickedProps = Partial<Omit<PickedState, "">>;

export type ReportType =
  | "ON_DURATION"
  | "ON_SATISFACTION"
  | "ON_ASA"
  | "ON_METHOD_CASE"
  | "ON_METHOD_GENDER";

export type ReportName =
  | "จำนวนผู้รับบริการระงับความรู้สึก แบ่งตามวิธีระงับความรู้สึก เพศ และอายุ"
  | "จำนวนผู้รับบริการระงับความรู้สึก แบ่งตามวิธีระงับความรู้สึก และประเภทเคส"
  | "จำนวนผู้รับบริการระงับความรู้สึก แบ่งตาม ASA"
  | "จำนวนผู้รับบริการระงับความรู้สึก แบ่งตามระดับความพอใจ"
  | "จำนวนผู้รับบริการระงับความรู้สึก แบ่งตามช่วงเวลาดมยา";

type FilterType = Partial<{
  startDate: string;
  endDate: string;
  selectedReport: ReportType;
  isExportExcel: boolean;
}>;

export type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

// Sequence
type SeqState = {
  sequence: "AnesthesiaStatReport";
  restart?: boolean;
  clear?: boolean;
};

// Handle Action
type ActionType =
  | {
      action: "PRINT_REPORT";
      type: ReportType;
      btnAction: string;
      data: FilterType; // (ยังไม่แน่ใจ)
      isExportExcel?: boolean;
    }
  | {
      action: "SEARCH_DATA";
      btnAction: string;
      startDate: string;
      endDate: string;
    };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => void;

export type SetProp = SetProperty<State & PickedState>;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  // Sequence
  AnesthesiaStatReportSequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "RunSequence"; params: Record<string, unknown> }
  | { message: "GetMasterData"; params: Record<string, unknown> };

export type Data = {
  division?: number;
}; // อาจจะไม่ใช้ แต่เผื่อไปก่อน

export const DataInitial = {};

// Const
const Masters = [] as const;

export const ACTIONS = {
  PRINT_REPORT: "PRINT_REPORT",
  SEARCH_DATA: "SEARCH_DATA",
} as const;

export const PRINT_OPTIONS = {
  STAT: "STAT",
  EXCEL: "EXCEL",
} as const;

export const FILTERS = {
  START_DATE: "filterStartDate",
  END_DATE: "filterEndDate",
  SELECTED_REPORT: "selectedReport",
} as const;

const defaultDate = dateToStringWithoutTimeBE(moment()); // 04/07/2567

type SummarizedReportItem = {
  anesthesiaTeam: string;
  ageRange: string[];
  amountMale: number[];
  amountFemale: number[];
  amountAll: number[];
};

type SummaryTotals = {
  total_male: number;
  total_female: number;
  total_all: number;
  total_type_ipd: number;
  total_type_opd: number;
  // total_type_hid: number;
};

const ageRanges = ["<= 1", "> 1 - 10", "> 10 - 20", "> 20 - 40", "> 40 - 60", "> 60"];

const getAgeRange = (age: number): string => {
  if (age <= 1) return "<= 1";
  if (age > 1 && age <= 10) return "> 1 - 10";
  if (age > 10 && age <= 20) return "> 10 - 20";
  if (age > 20 && age <= 40) return "> 20 - 40";
  if (age > 40 && age <= 60) return "> 40 - 60";
  return "> 60";
};

type AnesthesiaMethod = {
  [key: string]: string;
};

const ANES_MEHTOD: AnesthesiaMethod = {
  IsBalanced: "GA - Balanced",
  IsNitrousOxide: "GA - Nitrous Oxide",
  IsInhalation: "GA - Inhalation",
  IsTIVA: "GA - TIVA",
  IsSpinalBlock: "RA - Spinal",
  IsEpiduralBlock: "RA - Epidural",
  IsBrachialPlexusBlock: "RA - Brachial plexus",
  IsCombinedSpinalAndEpiduralBlock: "RA - CSE",
  IsCaudalblock: "RA - Caudal",
  IsMAC: "MAC",
  IsLMA: "LMA",
  IsCombinedGeneralAndRegionalAnesthesia: "Combined GA & RA",
};

const methodGA = {
  IsBalanced: "Balanced",
  IsNitrousOxide: "Nitrous Oxide",
  IsInhalation: "Inhalation",
  IsTIVA: "TIVA",
};

const methodRA = {
  IsSpinalBlock: "Spinal",
  IsEpiduralBlock: "Epidural",
  IsBrachialPlexusBlock: "Brachial plexus",
  IsCombinedSpinalAndEpiduralBlock: "CSE",
  IsCaudalblock: "Caudal",
};

const durationRanges = ["0 - 2 ชม.", "> 2 - 4 ชม.", "> 4 - 8 ชม.", "> 8 ชม.", "รวม"];

const satisfactionLevels = {
  "Very dissastisfied": "ระดับ 1",
  "Somewhat Dissastisfied": "ระดับ 2",
  Neutral: "ระดับ 3",
  "Somewhat Sastisfied": "ระดับ 4",
  "Very Sastisfied": "ระดับ 5",
  ประเมินไม่ได้: "ประเมินไม่ได้",
  ไม่ได้เยี่ยม: "ไม่ได้เยี่ยม",
} as const;

const SATISFACTION_LIST = {
  "Very dissastisfied": "1",
  "Somewhat Dissastisfied": "2",
  Neutral: "3",
  "Somewhat Sastisfied": "4",
  "Very Sastisfied": "5",
};

type SummarizedReportItemByMethod = {
  anesthesia_name: string;
  age_range: string[];
  amount_male: number[];
  amount_female: number[];
  amount_all: number[];
  is_opd: number;
  is_ipd: number;
  // is_hid: number;
  duration_range: string[];
  duration_count: number[];
  satisfactionCount: { [key: string]: number };
  satisfaction_level: string[];
  satisfaction_count: number[];
};

type EmergencyReportItem = {
  ASAPS: string;
  noEmergency: number;
  emergency: number;
  total: number;
};

type Handler<P = any, R = any> = (
  controller: WasmController<State & PickedState, Event, Data>,
  params: P
) => R;

/* ------------------- START ------------------- */
export const Start: Handler = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  });

  const state = controller.getState();

  // Fetch Data
  const [result, error] = await GetPreAnesData(controller, {
    startDate: defaultDate,
    endDate: defaultDate,
  });

  // Pre Data
  const { reportByMethod, reportByTeam } = generateSummarizedReport(result);
  const asapReport = generateEmergencyReport(result);

  if (error) {
    console.warn("Error to Fetch Pre Anes Data: ", error);

    controller.setState({
      AnesthesiaStatReportSequence: {
        ...state.AnesthesiaStatReportSequence,
        sequenceIndex: "Action",
        filterReport: {
          filterStartDate: defaultDate,
          filterEndDate: defaultDate,
        },
      },
    });
  } else {
    // get Format Anes Start (Current Date)
    const formatAnesData = getFormatData(result.items, state.AnesthesiaStatReportSequence);

    console.log("Pre Anes Data [Raw] (Start): ", result.items);
    console.log("Pre Anes Data [Format] (Start): ", formatAnesData);
    controller.setState({
      AnesthesiaStatReportSequence: {
        ...state.AnesthesiaStatReportSequence,
        sequenceIndex: "Action",
        preAnesData: result.items,
        formattedAnesData: formatAnesData,
        anesDataCount: formatAnesData?.length || 0,
        filterReport: {
          filterStartDate: defaultDate,
          filterEndDate: defaultDate,
        },
        summarizedReportByMethod: reportByMethod,
        summarizedRepotrByTeam: reportByTeam,
        summarizedEmergencyReport: asapReport,
      },
    });
  }
};

/* ------------------- ACTION ------------------- */
export const Action: Handler<ActionType> = async (controller, params) => {
  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.PRINT_REPORT]: HandlePrintReport,
    [ACTIONS.SEARCH_DATA]: HandleSearchData,
  };

  const action = params.action;

  return actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

const HandlePrintReport: Handler<Params<"PRINT_REPORT">> = async (controller, params) => {
  const state = controller.getState();

  if (params.data.isExportExcel) {
    // Export Excel File
    const excelData = {
      title: `รายงาน งานวิสัญญีวิทยา`,
      startAnesDate: state.AnesthesiaStatReportSequence?.filterReport?.filterStartDate,
      endAnesDate: state.AnesthesiaStatReportSequence?.filterReport?.filterEndDate,
      header: [
        "วันที่เริ่มดมยา, เวลาเริ่มต้น, HN, ชื่อ-สกุล, อายุ(ปี), เพศ, สิทธิ, ASA, วิธีระงับความรู้สึก, วันที่สิ้นสุด, เวลาสิ้นสุด, ช่วงเวลาที่ใช้ดมยา, ประเภทเคส, ระดับความพึงพอใจ",
      ],
      formattedAnesData: state.AnesthesiaStatReportSequence?.formattedAnesData,
    };

    exportToExcel(excelData);
  } else {
    const TYPE_HANDLERS: Partial<{
      [K in ReportType]: {
        name: ReportType;
        forms: (props: any) => Promise<TDocumentDefinitions>;
      };
    }> = {
      ON_METHOD_GENDER: {
        name: "ON_METHOD_GENDER",
        forms: FormAnesMethodGenderStatReport,
      },
      ON_METHOD_CASE: {
        name: "ON_METHOD_CASE",
        forms: FormAnesMethodCaseStatReport,
      },
      ON_ASA: {
        name: "ON_ASA",
        forms: FormAnesAsaStatReport,
      },
      ON_DURATION: {
        name: "ON_DURATION",
        forms: FormAnesDurationStatReport,
      },
      ON_SATISFACTION: {
        name: "ON_SATISFACTION",
        forms: FormAnesSatisfactionStatReport,
      },
    };

    const type = params.type;

    if (TYPE_HANDLERS[type]) {
      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnAction]: "LOADING" },
      });

      const result = await GetDataReport(controller, TYPE_HANDLERS[type].name);

      if (!result) {
        controller.setState({
          buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnAction]: "ERROR" },
        });
      } else {
        const reportData = {
          params: { ...params.data, print_user: state.django.user.full_name },
          ...result,
        };
        const form = TYPE_HANDLERS[type]?.forms;
        const docDef = await form?.(reportData);

        if (!docDef) {
          console.warn("Cannot Create PDF File!!");

          return;
        }

        const pdfMake = await getPdfMake(true);
        const pdf = pdfMake.createPdf(docDef);

        controller.setState({
          buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnAction]: "SUCCESS" },
        });

        pdf.open();
      }

      return;
    }
  }
};

const getAnesthesiaMethod = (data: any): string[] => {
  const methods: string[] = [];
  for (const key in ANES_MEHTOD) {
    if (data[key]) {
      methods.push(ANES_MEHTOD[key]);
    }
  }
  // Add "Unknown" if no methods are found
  if (methods.length === 0) {
    methods.push("Unknown");
  }

  return methods;
};

const calculateDuration = (start: string | null, end: string | null) => {
  if (!start || !end) return null;

  const [startHours, startMinutes] = start.split(":").map(Number);
  const [endHours, endMinutes] = end.split(":").map(Number);

  const startTime = new Date();
  startTime.setHours(startHours, startMinutes);

  const endTime = new Date();
  endTime.setHours(endHours, endMinutes);

  const durationMs = endTime.getTime() - startTime.getTime();
  const durationHours = durationMs / (1000 * 60 * 60);

  if (durationHours < 0) {
    return 24 + durationHours;
  }

  return durationHours;
};

const generateSummarizedReport = (data: any) => {
  const itemsMapByTeam: { [key: string]: SummarizedReportItem } = {};
  const itemsMapByMethod: { [key: string]: SummarizedReportItemByMethod } = {};
  const summaryTotals: SummaryTotals = {
    total_male: 0,
    total_female: 0,
    total_all: 0,
    total_type_ipd: 0,
    total_type_opd: 0,
    // total_type_hid: 0,
  };

  // re-list data
  const reListData = data?.items?.filter((item: any) => item.encounter_type !== "HID");

  reListData?.forEach((item: any) => {
    const anesthesiaTeam = item.data.AnesthesiaTeam || "Unknown";
    const anesthesiaMethods = getAnesthesiaMethod(item.data);
    const ageRange = getAgeRange(item.patient_age);
    const gender = item.patient_gender;
    const en_type = item.encounter_type;

    const startAnesTime = item.data.StartAnesTime;
    const endAnesTime = item.data.EndAnesTime;
    const anesTimeHour = item.data.TotalAnseTimeHour;
    const anesTimeMinute = item.data.TotalAnseTimeMinute;
    const anesTimeAllAsMinute = anesTimeHour * 60 + anesTimeMinute;
    const durationHours = calculateDuration(startAnesTime, endAnesTime);
    const patientSatisfaction = item.isPatientSatisfaction; //extra key fetch from the another form.

    if (!itemsMapByTeam[anesthesiaTeam]) {
      itemsMapByTeam[anesthesiaTeam] = {
        anesthesiaTeam,
        ageRange: Array(ageRanges.length).fill(""),
        amountMale: Array(ageRanges.length).fill(0),
        amountFemale: Array(ageRanges.length).fill(0),
        amountAll: Array(ageRanges.length).fill(0),
      };
    }

    const ageRangeIndex = ageRanges.indexOf(ageRange);

    // Update team-based report
    itemsMapByTeam[anesthesiaTeam].ageRange[ageRangeIndex] = ageRange;
    if (gender === "Male") {
      itemsMapByTeam[anesthesiaTeam].amountMale[ageRangeIndex] += 1;
      summaryTotals.total_male += 1;
    } else if (gender === "Female") {
      itemsMapByTeam[anesthesiaTeam].amountFemale[ageRangeIndex] += 1;
      summaryTotals.total_female += 1;
    }
    itemsMapByTeam[anesthesiaTeam].amountAll[ageRangeIndex] += 1;
    summaryTotals.total_all += 1;

    if (en_type === "OPD") {
      summaryTotals.total_type_opd += 1;
    } else if (en_type === "IPD") {
      summaryTotals.total_type_ipd += 1;
      // } else if (en_type === "HID") {
      //   summaryTotals.total_type_hid += 1;
    }

    // Init item if method is not exist in the object
    anesthesiaMethods.forEach((method) => {
      if (!itemsMapByMethod[method]) {
        // Check not exist in the object
        if (method === "Test Full Range") {
          itemsMapByMethod[method] = {
            anesthesia_name: method,
            age_range: ageRanges.map((range) => range),
            amount_male: Array(ageRanges.length).fill(0),
            amount_female: Array(ageRanges.length).fill(0),
            amount_all: Array(ageRanges.length).fill(0),
            is_opd: 0,
            is_ipd: 0,
            // is_hid: 0,
            duration_range: durationRanges.map((range) => range),
            duration_count: Array(durationRanges.length).fill(0),
            satisfactionCount: Object.fromEntries(
              Object.values(satisfactionLevels).map((level) => [level, 0])
            ),
            satisfaction_level: Object.values(satisfactionLevels),
            satisfaction_count: Array(Object.values(satisfactionLevels).length).fill(0),
          };
        } else {
          itemsMapByMethod[method] = {
            anesthesia_name: method,
            age_range: Array(ageRanges.length).fill(""),
            amount_male: Array(ageRanges.length).fill(0),
            amount_female: Array(ageRanges.length).fill(0),
            amount_all: Array(ageRanges.length).fill(0),
            is_opd: 0,
            is_ipd: 0,
            // is_hid: 0,
            duration_range: durationRanges.map((range) => range),
            duration_count: Array(durationRanges.length).fill(0),
            satisfactionCount: Object.fromEntries(
              Object.values(satisfactionLevels).map((level) => [level, 0])
            ),
            satisfaction_level: Object.values(satisfactionLevels),
            satisfaction_count: Array(Object.values(satisfactionLevels).length).fill(0),
          };
        }
      }
    });

    // anes duration level calculation
    // if (durationHours !== null) {
    //   const durationRangeIndex = durationRanges.findIndex((range) => {
    //     if (range === "0 - 2 ชม." && durationHours <= 2) return true;
    //     if (range === "> 2 - 4 ชม." && durationHours > 2 && durationHours <= 4) return true;
    //     if (range === "> 4 - 8 ชม." && durationHours > 4 && durationHours <= 8) return true;
    //     if (range === "> 8 ชม." && durationHours > 8) return true;
    //     return false;
    //   });

    //   anesthesiaMethods.forEach((method) => {
    //     itemsMapByMethod[method].duration_count[durationRangeIndex] += 1;
    //     itemsMapByMethod[method].duration_count[4] += 1;
    //   });
    // }

    if (anesTimeAllAsMinute !== null) {
      const durationTimeRangeIndex = durationRanges.findIndex((range) => {
        if (range === "0 - 2 ชม." && anesTimeAllAsMinute <= 120) return true;
        if (range === "> 2 - 4 ชม." && anesTimeAllAsMinute > 120 && anesTimeAllAsMinute <= 240)
          return true;
        if (range === "> 4 - 8 ชม." && anesTimeAllAsMinute > 240 && anesTimeAllAsMinute <= 480)
          return true;
        if (range === "> 8 ชม." && anesTimeAllAsMinute > 480) return true;
        return false;
      });

      anesthesiaMethods.forEach((method) => {
        itemsMapByMethod[method].duration_count[durationTimeRangeIndex] += 1;
        itemsMapByMethod[method].duration_count[4] += 1;
      });
    }

    // satisfactionLevels calculation
    console.log("-------", patientSatisfaction, satisfactionLevels[patientSatisfaction]);

    // Update method-based
    anesthesiaMethods.forEach((method) => {
      itemsMapByMethod[method].age_range[ageRangeIndex] = ageRange;
      if (gender === "Male") {
        itemsMapByMethod[method].amount_male[ageRangeIndex] += 1;
      } else if (gender === "Female") {
        itemsMapByMethod[method].amount_female[ageRangeIndex] += 1;
      }
      itemsMapByMethod[method].amount_all[ageRangeIndex] += 1;

      if (en_type === "OPD") {
        itemsMapByMethod[method].is_opd += 1;
      } else if (en_type === "IPD") {
        itemsMapByMethod[method].is_ipd += 1;
        // } else if (en_type === "HID") {
        //   itemsMapByMethod[method].is_hid += 1;
      }

      if (patientSatisfaction && satisfactionLevels[patientSatisfaction]) {
        itemsMapByMethod[method].satisfactionCount[satisfactionLevels[patientSatisfaction]] += 1;

        const satisfactionIndex = Object.keys(satisfactionLevels).indexOf(patientSatisfaction);
        itemsMapByMethod[method].satisfaction_count[satisfactionIndex] += 1;
      }
    });
  });

  const itemsByTeam = Object.values(itemsMapByTeam);
  const itemsByMethod = Object.values(itemsMapByMethod);

  let summaryTotalsExtra = {
    ...summaryTotals,
    duration: itemsByMethod.reduce(
      (totals, item) => {
        item.duration_count.forEach((count, idx) => {
          totals.duration_count[idx] += count;
        });
        return totals;
      },
      {
        duration_range: durationRanges.map((range) => range),
        duration_count: new Array(durationRanges.length).fill(0),
      }
    ),
    satisfaction: itemsByMethod.reduce(
      (totals, item) => {
        console.log(item);
        item.satisfaction_count.forEach((count, idx) => {
          totals.satisfaction_count[idx] += count;
        });
        return totals;
      },
      {
        satisfaction_level: Object.values(satisfactionLevels),
        satisfaction_count: new Array(Object.values(satisfactionLevels).length).fill(0),
      }
    ),
  };

  return {
    reportByTeam: {
      items: itemsByTeam,
      summaryTotals: summaryTotals,
    },
    reportByMethod: {
      items: itemsByMethod,
      summaryTotals: summaryTotalsExtra,
    },
  };
};

const generateEmergencyReport = (data: any) => {
  const emergencyReport: { [key: string]: EmergencyReportItem } = {};
  const emergencyTotals = { noEmergency: 0, emergency: 0, total: 0 };

  // re-list data
  const reListData = data?.items?.filter((item: any) => item.encounter_type !== "HID");

  reListData?.forEach((item: any) => {
    const ASAPS = item.data.ASAPS || "Unknown";
    const isEmergency = item.data.IsEmergency;

    if (!emergencyReport[ASAPS]) {
      emergencyReport[ASAPS] = {
        ASAPS,
        noEmergency: 0,
        emergency: 0,
        total: 0,
      };
    }

    if (isEmergency) {
      emergencyReport[ASAPS].emergency += 1;
      emergencyTotals.emergency += 1;
    } else {
      emergencyReport[ASAPS].noEmergency += 1;
      emergencyTotals.noEmergency += 1;
    }

    emergencyReport[ASAPS].total += 1;
    emergencyTotals.total += 1;
  });

  const emergencyReportItems = Object.values(emergencyReport);

  return {
    emergencyReport: emergencyReportItems,
    emergencyTotals,
  };
};

const HandleSearchData: Handler<Params<"SEARCH_DATA">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnAction]: "LOADING" },
  });

  const [result, error] = await GetPreAnesData(controller, params);

  if (error) {
    console.warn("Error to Fetch Pre Anes Data: ", error);

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnAction]: "ERROR" },
    });
  } else {
    // console.log("Yeti Anes Data Search: ", result?.items);

    const { reportByMethod, reportByTeam } = generateSummarizedReport(result);
    const asapReport = generateEmergencyReport(result);

    console.log("reportByMethod", reportByMethod);
    console.log("asapReport", asapReport);

    // get Format Data
    const formatAnesData = getFormatData(result?.items, state.AnesthesiaStatReportSequence);

    controller.setState({
      AnesthesiaStatReportSequence: {
        ...state.AnesthesiaStatReportSequence,
        preAnesData: result.items,
        formattedAnesData: formatAnesData,
        anesDataCount: formatAnesData?.length || 0,
        summarizedReportByMethod: reportByMethod,
        summarizedRepotrByTeam: reportByTeam,
        summarizedEmergencyReport: asapReport,
      },
      buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnAction]: "SUCCESS" },
    });
  }
};

/* ------------------------------------------------------ */

/*                          APIS                          */

/* ------------------------------------------------------ */
const GetPreAnesData: Handler<FilterType> = async (controller, params) => {
  const [result, error] = await PreAnestheticReportList.list({
    apiToken: controller.apiToken,
    params: {
      start: params.startDate,
      end: params.endDate,
      form_code: `CardAnestheticAssessmentRecord`,
      form_version: `1.0`,
    },
    extra: {
      division: controller.data.division,
      limit: 10,
    },
  });

  if (error) {
    return [null, error];
  } else {
    const preAnesReportData = (result?.items || []).filter((item: any) => {
      let startAnes = moment(item?.data?.StartAnesDate, "DD/MM/YYYY");
      let endAnes = moment(item?.data?.EndAnesDate, "DD/MM/YYYY");
      let startDate = moment(params.startDate, "DD/MM/YYYY");
      let endDate = moment(params.endDate, "DD/MM/YYYY");
      return startAnes.isBetween(startDate, endDate) && endAnes.isBetween(startDate, endDate);
    });

    return [{ ...result, items: preAnesReportData }, null];
  }
};

const GetDataReport: Handler<ReportType> = async (controller, params) => {
  const state = controller.getState();

  const useMethodData = ["ON_METHOD_GENDER", "ON_METHOD_CASE", "ON_DURATION", "ON_SATISFACTION"];
  const useEmergencyData = ["ON_ASA"];

  if (
    !state.AnesthesiaStatReportSequence?.summarizedReportByMethod ||
    !state.AnesthesiaStatReportSequence?.summarizedEmergencyReport
  ) {
    return null;
  } else {
    if (useMethodData.includes(params)) {
      return state.AnesthesiaStatReportSequence?.summarizedReportByMethod;
    }
    if (useEmergencyData.includes(params)) {
      return state.AnesthesiaStatReportSequence.summarizedEmergencyReport;
    }
  }
};

/**============================================
 **                   UTILS
 *=============================================**/
const getAnesMethod = (method: any, type: any) => {
  for (const key in type) {
    if (method.IsOthers) {
      if (method.IsGeneralAnesthesia) {
        return "GA";
      } else if (method.IsRegionalAnesthesia) {
        return "RA";
      }
    }
    if (method[key]) {
      return type[key];
    }
  }

  return null;
};

const filterDataMedhod = (method: any, typeData: any) => {
  let dataMedhod = Object.keys(typeData).map((items: string) => {
    if (method?.[items]) {
      return typeData?.[items];
    } else {
      return null;
    }
  });

  return dataMedhod.filter((items: any) => items !== null).join(", ");
};

const formatAnesMethod = (method: any) => {
  return `${method.IsGeneralAnesthesia ? `GA - ${filterDataMedhod(method, methodGA)}\n` : ""} ${
    method.IsRegionalAnesthesia ? `RA - ${filterDataMedhod(method, methodRA)}\n` : ""
  }${method.IsMAC ? `\n MAC` : ""} ${method.IsLMA ? `\n LMA` : ""} ${
    method.IsCombinedGeneralAndRegionalAnesthesia ? `\n Combined GA & RA` : ""
  }`;
};

const getAfterClinic = (isAfterClinic: boolean) => {
  if (isAfterClinic) {
    return "(นอกเวลา)";
  }

  return "(ในเวลา)";
};

const getIsEmergency = (isEmergency: boolean) => {
  if (isEmergency) {
    return "E";
  }

  return "";
};

const formatHn = (hn: string): string => {
  if (hn) {
    if (hn.includes("-")) {
      return hn.replace(/-/g, "");
    }
  }

  return hn;
};

const getSatisfactionLevel = (satisfactionText: string) => {
  if (satisfactionText) {
    const toLowerText = satisfactionText.toLowerCase().trim();

    for (const [key, value] of Object.entries(SATISFACTION_LIST)) {
      if (key.toLowerCase().trim() === toLowerText) {
        return `${value}-`;
      }
    }
  }

  return "";
};

const removeSeconds = (timeString: string) => {
  if (timeString) {
    return timeString.slice(0, 5);
  }
  return timeString;
};

const replaceSlashDate = (dateString: string) => {
  return dateString.replace(/\//g, "");
};

/**============================================
 **                 HANDLERS
 *=============================================**/
const getFormatData = (data: any[], anesthes: any) => {
  if (data && Array.isArray(data)) {
    // re-list data
    return data
      .filter(
        (item: any) =>
          item.encounter_type !== "HID" &&
          anesthes?.filterReport?.filterStartDate <= item.data?.StartAnesDate
      )
      .map((item: any) => {
        return {
          ...item,
          formatHn: formatHn(item.patient_hn), // HN
          anesDate: item.data?.StartAnesDate, //* วันที่เริ่มดมยา
          startTime: removeSeconds(item.data?.StartAnesTime), // เวลาเริ่มดมยา
          endDate: item.data?.EndAnesDate, //* วันที่สิ้นสุด
          endTime: item.data?.EndAnesTime, // เวลาที่สิ้นสุด
          timeDuration:
            item.data?.TotalAnseTimeHour > 0
              ? `${item.data?.TotalAnseTimeHour} ชม. ${item.data?.TotalAnseTimeMinute} นาที`
              : `${item.data?.TotalAnseTimeMinute} นาที`, // ช่วงเวลาดมยา
          asa: `${item.data?.ASAPS}${getIsEmergency(item.data?.IsEmergency)}`, // ASA
          // anesMethod: getAnesMethod(item.data, ANES_MEHTOD), // วิธีระงับความรู้สึก
          anesMethod: formatAnesMethod(item.data),
          formatCase: item.or_case, // `${item.encounter_type} ${getAfterClinic(item.data?.startAfterClinic)}`, // ประเภทเคส
          formatSatisfaction: `${getSatisfactionLevel(item.isPatientSatisfaction)}${
            item.isPatientSatisfaction || ""
          }`, // ระดับพึงพอใจ
        };
      });
  }

  return [];
};

const exportToExcel = (data: Record<string, any>) => {
  const wb = XLSXUtils.book_new();
  const ws = XLSXUtils.aoa_to_sheet([]);

  // Specific
  ws["A1"] = { v: data.title, t: "s" };
  ws["A2"] = { v: `วันที่เริ่มดมยา`, t: "s" };
  ws["B2"] = { v: data.startAnesDate, t: "s" };
  ws["C2"] = { v: `ถึง`, t: "s" };
  ws["D2"] = { v: data.endAnesDate, t: "s" };

  // Header of Table
  const headers = data.header[0].split(", ");
  headers.forEach((header: any, index: number) => {
    const headerCell = XLSXUtils.encode_cell({ r: 3, c: index });
    //! cannot set font as bold style yet
    ws[headerCell] = { v: header, t: "s" };

    if (!ws[headerCell].s) {
      ws[headerCell].s = {};
    }
    if (!ws[headerCell].s.font) {
      ws[headerCell].s.font = {};
    }
    ws[headerCell].s.font.bold = true;
  });

  // Data of Table
  const dataTable = data.formattedAnesData.map((item: any) => [
    item.anesDate,
    item.startTime,
    item.formatHn,
    item.patient_full_name,
    item.patient_age,
    item.patient_gender,
    item.payer,
    item.asa,
    item.anesMethod,
    item.endDate,
    item.endTime,
    item.timeDuration,
    item.formatCase,
    item.formatSatisfaction,
  ]);

  XLSXUtils.sheet_add_aoa(ws, dataTable, { origin: "A5" });

  // if column is null, it will set blank cell
  ws["!cols"] = headers.map(() => ({ wch: 15 }));

  XLSXUtils.book_append_sheet(wb, ws, "Sheet1");

  XLSXWriteFile(
    wb,
    `${replaceSlashDate(data.startAnesDate)}_${replaceSlashDate(
      data.endAnesDate
    )}_AnesStatReport.xlsx`
  );
};
