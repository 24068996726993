import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import FormDataDetailByEncounter from "issara-sdk/apis/FormDataDetailByEncounter_apps_PTM";
import moment from "moment";
import WasmController from "react-lib/frameworks/WasmController";
import { beToAd, formatDate } from "react-lib/utils";
import { beStringToMoment } from "react-lib/utils/dateUtils";
import { fallRiskNursingOptions, nursingCareOptions } from "react-lib/apps/HISV3/ADM/sequence/PreAssessment";
// apis

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  masterOptions?: any;
  buttonLoadCheck?: any; // {cardName: LOADING || SUCCESS || ERROR}

  // common
  selectedEncounter?: any;
  django?: any;
  selectedPatient?: any;

  // sequence
  ReAssessmentSequence?: {
    sequenceIndex?: string | null;
    id?: number | null;
    // data
    sleepingEnough?: string;
    sleepingPerDay?: string;
    sleepingProblem?: string;
    sleepingProblemRemark?: string;
    // data
    painData?: {
      saveShift?: "Day" | "Night";
      saveData?: any;
      saveTime?: any;
    };
    riskFallData?: {
      saveShift?: "Day" | "Night";
      saveData?: any;
      saveTime?: any;
    };
    sleepData?: {
      saveShift?: "Day" | "Night";
      saveData?: any;
      saveTime?: any;
    };
    venousData?: {
      saveShift?: "Day" | "Night";
      saveData?: any;
      saveTime?: any;
    };
    surgicalBeforeData?: {
      type: "SURGICAL_BEFORE";
      saveShift?: "Day" | "Night";
      saveData?: any;
      saveTime?: any;
    };
    surgicalAfterData?: {
      type: "SURGICAL_AFTER";
      saveShift?: "Day" | "Night";
      saveData?: any;
      saveTime?: any;
    };
    urinaryData?: {
      saveShift?: "Day" | "Night";
      saveData?: any;
      saveTime?: any;
    };
    // history
    painDataHistory?: {
      startDate?: any;
      endDate?: any;
      data?: any;
    };
    riskFallDataHistory?: {
      startDate?: any;
      endDate?: any;
      data?: any;
    };
    sleepDataHistory?: {
      startDate?: any;
      endDate?: any;
      data?: any;
    };
    venousDataHistory?: {
      startDate?: any;
      endDate?: any;
      data?: any;
    };
    surgicalDataHistory?: {
      startDate?: any;
      endDate?: any;
      data?: any;
    }
    urinaryDataHistory?: {
      startDate?: any;
      endDate?: any;
      data?: any;
    };

    painStatus?: any;
    assessPainLevel?: any;
    painLevel?: any;
    painArea?: any;
    painNature?: any;
    sensesNursingCare?: any;
    riskFall?: any;
    riskFallRemark?: any;
    nursingCare?: any;
    sleepPill?: any;
    sleepPillRemark?: any;

    // Report ID
    reAssessmentPainID?: any;
    reAssessmentRiskFallIPD?: any;
    reAssessmentSleepIPD?: any;
    reAssessmentVenousIPD?: any;
    reAssessmentSurgicalBeforeIPD?: any;
    reAssessmentSurgicalAfterIPD?: any;
    reAssessmentUrinaryIPD?: any;

    // Pain data
    painQuestion?: {
      painStatus?: "ปวด" | "ไม่ปวด" | null;
      painAssessmentTool?: string | null;
      painScore?: string | null;
      painLocation?: string | null;
      painDurationMinute?: string | null;
      painDurationSecond?: string | null;
      painCharacteristic?: string | null;
      painCharacteristicNote?: string | null;
      painFrequency?: string | null;
      painInformedPhysician?: "Yes" | "No" | null;
      painInformedPhysicianNote?: string | null;
      painNonMidicalIntervention?: any[] | null;
      painMedicationGive?: "Yes" | "No" | null;
      painMedicationGiveNote?: string | null;
      painMedicationGiveTime?: string | null;
      painSedatScoreBefore?: string | null;
      painSedatScoreAfter?: string | null;
      painScoreAfterIntervention?: "IV min" | "IM 30 min" | "Oral / non med 60 min" | null;
      painScoreAfterInterventionScore?: string | null;
      painScoreAfterInterventionTime?: string | null;
      painSideEffect?: string | null;
      painSideEffectNote?: string | null;
    };

    // Risk Fall data
    riskFallQuestion?: {
      riskFallScore?: "Assessment" | "Re-assessment" | null;
      riskFallAge?: string | null;
      riskFallAgeScore?: number | string | null;
      riskFallHistory?: string | null;
      riskFallHistoryScore?: number | string | null;
      riskFallMental?: string | null;
      riskFallMentalScore?: number | string | null;
      riskFallSensory?: any[] | null;
      riskFallSensoryDeficit?: string | null;
      riskFallSensoryScore?: number | string | null;
      riskFallElimination?: any[] | null;
      riskFallEliminationProblem?: string | null;
      riskFallEliminationScore?: number | string | null;
      riskFallMedication?: string | null;
      riskFallMedicationScore?: number | string | null;
      riskFallMobily?: string | null;
      riskFallMobilyScore?: number | string | null;
      riskFallTotalScore?: number | string | null;
      riskFallTotalPrecaution?: string | null;
    };

    // Venous data
    venousQuestion?: {
      venousQuestOne?: "Yes" | "No" | "NA" | null;
      venousQuestTwo?: "Yes" | "No" | "NA" | null;
      venousQuestThree?: "Yes" | "No" | "NA" | null;
      venousQuestFour?: "Yes" | "No" | "NA" | null;
      venousQuestFive?: "Yes" | "No" | "NA" | null;
      venousQuestSix?: "Yes" | "No" | "NA" | null;
      venousQuestSeven?: "Yes" | "No" | "NA" | null;
      venousQuestEight?: "Yes" | "No" | "NA" | null;
    };

    // Surgical data
    surgicalBeforeQuestion?: {
      surgicalBeforeQuestOne?: "Yes" | "No" | "NA" | null;
      surgicalBeforeQuestTwo?: "Yes" | "No" | "NA" | null;
      surgicalBeforeQuestThree?: "Yes" | "No" | "NA" | null;
      surgicalBeforeQuestFour?: "Yes" | "No" | "NA" | null;
    };
    surgicalAfterQuestion?: {
      surgicalAfterQuestOne?: "Yes" | "No" | "NA" | null;
      surgicalAfterQuestTwo?: "Yes" | "No" | "NA" | null;
      surgicalAfterQuestThree?: "Yes" | "No" | "NA" | null;
    };

    // Urinary data
    urinaryQuestion?: {
      urinaryQuestOne?: "Yes" | "No" | "NA" | null;
      urinaryQuestTwo?: "Yes" | "No" | "NA" | null;
      urinaryQuestThree?: "Yes" | "No" | "NA" | null;
      urinaryQuestFour?: "Yes" | "No" | "NA" | null;
      urinaryQuestFourSubOne?: "Yes" | "No" | "NA" | null;
      urinaryQuestFourSubTwo?: "Yes" | "No" | "NA" | null;
      urinaryQuestFourSubThree?: "Yes" | "No" | "NA" | null;
      urinaryQuestFourSubFour?: "Yes" | "No" | "NA" | null;
      urinaryQuestFourSubFive?: "Yes" | "No" | "NA" | null;
    };
  } | null;

  // options
  reAssessmentOptions?: {
    painRiskFallOptions?: any[];
    painNursingCareOptions?: any[];
    sensesNursingCareOptions?: any[];
    painLevel?: any[];
    painFrequency?: any[];
    painNonMedicalIntervention?: any[];
    painSedatScore?: any[];
    painSideEffect?: any[];
    painAssessmentTool?: any[];
    riskFallAgeRange?: any[];
    riskFallHistory?: any[];
    riskFallMental?: any[];
    riskFallSensory?: any[];
    riskFallDeficit?: any[];
    riskFallElimination?: any[];
    riskFallProblem?: any[];
    riskFallMedication?: any[];
    riskFallMobily?: any[];
    riskFallResult?: any[];
  };
};

export const StateInitial: State = {
  // sequence
  ReAssessmentSequence: {
    sequenceIndex: null,
    id: null,
    // data
    sleepingEnough: "",
    sleepingPerDay: "",
    sleepingProblem: "",
    sleepingProblemRemark: "",
    painDataHistory: {
      startDate: "",
      endDate: "",
      data: [],
    },
    riskFallDataHistory: {
      startDate: "",
      endDate: "",
      data: [],
    },
    sleepDataHistory: {
      startDate: "",
      endDate: "",
      data: [],
    },
  },

  // options
  reAssessmentOptions: {
    painRiskFallOptions: [],
    painNursingCareOptions: [],
    sensesNursingCareOptions: [],
    painLevel: [],
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
  masterData?: { [name: string]: any };
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

// Const
const SAVE_LIST = [
  "savePain",
  "saveRiskFall",
  "saveSleep",
  "saveVenous",
  "saveSurgicalBefore",
  "saveSurgicalAfter",
  "saveUrinary"
] as const;

export const painLevelOptions = [
    { key: "0", value: "0", text: "0" },
    { key: "1", value: "1", text: "1" },
    { key: "2", value: "2", text: "2" },
    { key: "3", value: "3", text: "3" },
    { key: "4", value: "4", text: "4" },
    { key: "5", value: "5", text: "5" },
    { key: "6", value: "6", text: "6" },
    { key: "7", value: "7", text: "7" },
    { key: "8", value: "8", text: "8" },
    { key: "9", value: "9", text: "9" },
    { key: "10", value: "10", text: "10" },
];

export const frequencyOptions = [
  { key: "0", value: "Continuous", text: "Continuous" },
  { key: "1", value: "Intermittent", text: "Intermittent" }
];

export const nonMedicalInterventionOptions = [
  { key: "0", value: "None", text: "None" },
  { key: "1", value: "Music therapy", text: "Music therapy" },
  { key: "2", value: "Advice", text: "Advice" },
  { key: "3", value: "Relaxation Techniques", text: "Relaxation Techniques" },
  { key: "4", value: "Massage", text: "Massage" },
  { key: "5", value: "Tallking", text: "Tallking" },
  { key: "6", value: "Position", text: "Position" },
  { key: "7", value: "Aroma therapy", text: "Aroma therapy" },
  { key: "8", value: "Cold", text: "Cold" },
  { key: "9", value: "Heat", text: "Heat" },
];

export const sedatScoreOptions = [
  { key: "0", value: "0", text: "0" },
  { key: "1", value: "1", text: "1" },
  { key: "2", value: "2", text: "2" },
  { key: "3", value: "3", text: "3" },
];

export const sideEffectOptions = [
  { key: "0", value: "No side effects", text: "No side effects" },
  { key: "1", value: "Dry mouth", text: "Dry mouth" },
  { key: "2", value: "Nausea", text: "Nausea" },
  { key: "3", value: "Respiratory Depression", text: "Respiratory Depression" },
  { key: "4", value: "Pruritus", text: "Pruritus" },
  { key: "5", value: "Vomiting", text: "Vomiting" },
  { key: "6", value: "Altered Mental Status", text: "Altered Mental Status" },
  { key: "7", value: "Sleep Disturbance", text: "Sleep Disturbance" },
  { key: "8", value: "Urinary Retention", text: "Urinary Retention" },
  { key: "9", value: "Other", text: "Other" },
];

export const assessmentToolOptions = [
  { key: "0", value: "< 1 years (NIPS*)", text: "< 1 years (NIPS*)" },
  { key: "1", value: "1-3 < 1 year (FLACC*)", text: "1-3 < 1 year (FLACC*)" },
  { key: "2", value: ">3-8 year (FACES)", text: ">3-8 year (FACES)" },
  { key: "4", value: "> 8 years (Numeric scale)", text: "> 8 years (Numeric scale)"},
  {
    key: "3",
    value: "No - Communicative patient (BPS*)",
    text: "No - Communicative patient (BPS*)",
  },
];

export const characteristicOptions = [
  { key: "0", value: "Burn", text: "Burn" },
  { key: "1", value: "Prick", text: "Prick" },
  { key: "2", value: "Colic", text: "Colic" },
  { key: "3", value: "Sharp", text: "Sharp" },
  { key: "4", value: "Dull", text: "Dull" },
  { key: "5", value: "Throbbing", text: "Throbbing" },
  { key: "6", value: "Other", text: "Other" },
];

//* Note ต่ำกว่า 15 ให้ Score เป็น null ไปก่อน
export const riskFallAgeRange = [
  { key: "0", value: ">15-20 year old", text: ">15-20 year old", min: 15, max: 20, score: 0 },
  { key: "1", value: ">20-60 year old", text: ">20-60 year old", min: 21, max: 60, score: 1 },
  { key: "2", value: ">60-74 year old", text: ">60-74 year old", min: 61, max: 74, score: 2 },
  { key: "3", value: ">74 year old", text: ">74 year old", min: 75, max: Infinity, score: 3 },
];

export const historyOfFallOptions = [
  { key: "0", value: "No falls", text: "No falls", score: 0 },
  { key: "1", value: "Fell in the last 6-12 months", text: "Fell in the last 6-12 months", score: 1 },
  { key: "2", value: "Fell in the last 1-6 months", text: "Fell in the last 1-6 months", score: 2 },
  { key: "3", value: "Fell < 1 months", text: "Fell < 1 months", score: 3 },
];

export const mentalStatusOptions = [
  { key: "0", value: "Fully oriented", text: "Fully oriented", score: 0 },
  { key: "1", value: "Oriented to 2 out of  3 items", text: "Oriented to 2 out of  3 items", score: 1 },
  { key: "2", value: "Oriented to 1 out of 3 items", text: "Oriented to 1 out of 3 items", score: 2 },
  { key: "3", value: "Disoriented", text: "Disoriented", score: 3 },
];

export const deficitOptions = [
  { key: "0", value: "No deficits", text: "No deficits", score: 0 },
  { key: "1", value: "1 deficit", text: "1 deficit", score: 1 },
  { key: "2", value: "2 deficits", text: "2 deficits", score: 2 },
  { key: "3", value: "≥3 deficits", text: "≥3 deficits", score: 3 },
];

export const problemOptions = [
  { key: "0", value: "No problems", text: "No problems", score: 0 },
  { key: "1", value: "1 problem", text: "1 problem", score: 1 },
  { key: "2", value: "2 problems", text: "2 problems", score: 2 },
  { key: "3", value: "≥3 problems", text: "≥3 problems", score: 3 },
];

export const sensoryOptions = [
  { key: "0", value: "V", text: "V" },
  { key: "1", value: "S", text: "S" },
  { key: "2", value: "H", text: "H" },
  { key: "3", value: "T", text: "T" },
  { key: "4", value: "L", text: "L" },
];

export const eliminationOptions = [
  { key: "0", value: "U", text: "U" },
  { key: "1", value: "N", text: "N" },
  { key: "2", value: "F", text: "F" },
  { key: "3", value: "V", text: "V" },
  { key: "4", value: "D", text: "D" },
  { key: "5", value: "L", text: "L" },
  { key: "6", value: "I", text: "I" },
];

export const medicationOptions = [
  { key: "0", value: "Cardiovascular medication", text: "Cardiovascular medication", score: 1 },
  { key: "1", value: "Antihistamines", text: "Antihistamines", score: 1 },
  { key: "2", value: "Laxatives", text: "Laxatives", score: 1 },
  { key: "3", value: "Diuretics", text: "Diuretics", score: 1 },
  { key: "4", value: "Narcotics", text: "Narcotics", score: 2 },
  { key: "5", value: "Psychotropics", text: "Psychotropics", score: 2 },
  { key: "6", value: "Sedative", text: "Sedative", score: 2 },
];

export const mobilyOptions = [
  { key: "0", value: "Ambulate alone with steady gait", text: "Ambulate alone with steady gait", score: 0 },
  { key: "1", value: "Ambulate steadily with assistance (Person or assistive device)", text: "Ambulate steadily with assistance (Person or assistive device)", score: 1 },
  { key: "2", value: "Unsteady gait", text: "Unsteady gait", score: 2 },
  { key: "3", value: "Impaired judgement and unsteady gait", text: "Impaired judgement and unsteady gait", score: 3 },
];

export const riskFallResultOptions = [
  { key: "0", value: "Standard Fall Precaution (Score 0-6)", text: "Standard Fall Precaution (Score 0-6)", min: 0, max: 6 },
  { key: "1", value: "Strict Fall Precaution (Score 7-18)", text: "Strict Fall Precaution (Score 7-18)", min: 7, max: 18 },
  { key: "2", value: "Highly Strict Fall Precaution (Score >18)", text: "Highly Strict Fall Precaution (Score >18)", min: 18, max: Infinity },
];

export const Start: Handler = async (controller, params) => {
  var state = controller.getState();
  // console.log("Yeti ReAssessment State: ", state);
  // Options
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["education", {}],
        ["maritalStatus", {}],
        ["religion", {}],
        ["career", {}],
        ["race", {}],
        ["nationality", {}],
        ["pain", {}],
        ["location", {}],
        ["characteristic", {}],
      ],
    },
  });

  const [
    fetchPain,
    fetchRiskFall,
    fetchSleep,
    fetchVenous,
    fetchSurgicalBefore,
    fetchSurgicalAfter,
    fetchUrinary,
  ] = await Promise.allSettled([
    FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: params.selectedEncounter || state.selectedEncounter?.id,
        form_code: "CardReAssessmentPainIPD",
        form_version: 1.0,
      },
      extra: { division: controller.data.division },
    }),
    FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: params.selectedEncounter || state.selectedEncounter?.id,
        form_code: "CardReAssessmentRiskFallIPD",
        form_version: 1.0,
      },
      extra: { division: controller.data.division },
    }),
    FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: params.selectedEncounter || state.selectedEncounter?.id,
        form_code: "CardReAssessmentSleepIPD",
        form_version: 1.0,
      },
      extra: { division: controller.data.division },
    }),
    FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: params.selectedEncounter || state.selectedEncounter?.id,
        form_code: "CardReAssessmentVenousIPD",
        form_version: 1.0,
      },
      extra: { division: controller.data.division },
    }),
    FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: params.selectedEncounter || state.selectedEncounter?.id,
        form_code: "CardReAssessmentSurgicalBeforeIPD",
        form_version: 1.0,
      },
      extra: { division: controller.data.division },
    }),
    FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: params.selectedEncounter || state.selectedEncounter?.id,
        form_code: "CardReAssessmentSurgicalAfterIPD",
        form_version: 1.0,
      },
      extra: { division: controller.data.division },
    }),
    FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: params.selectedEncounter || state.selectedEncounter?.id,
        form_code: "CardReAssessmentUrinaryIPD",
        form_version: 1.0,
      },
    }),
  ]);

  // Promise.allSettled return {status, value}
  let fetchPainIPD = fetchPain.value;
  let fetchRiskFallIPD = fetchRiskFall.value;
  let fetchSleepIPD = fetchSleep.value;
  let fetchVenousIPD = fetchVenous.value;
  let fetchSurgicalBeforeIPD = fetchSurgicalBefore.value;
  let fetchSurgicalAfterIPD = fetchSurgicalAfter.value;
  let fetchUrinaryIPD = fetchUrinary.value;

  const toDay = moment();
  const formatDateToDay = formatDate(toDay);
  const sevenDaysAgo = formatDate(toDay.clone().subtract(7, "days"));
  const timeToDay = toDay.format("HH:mm");

  // get Patient Age
  const patientAgeResult = await getPatientAgeRiskFall(state?.selectedPatient?.age);

  controller.setState({
    ReAssessmentSequence: {
      sequenceIndex: "AddOrEdit",
      reAssessmentPainID: fetchPainIPD?.[0]?.pk,
      reAssessmentRiskFallIPD: fetchRiskFallIPD?.[0]?.pk,
      reAssessmentSleepIPD: fetchSleepIPD?.[0]?.pk,
      reAssessmentVenousIPD: fetchVenousIPD?.[0]?.pk,
      reAssessmentSurgicalBeforeIPD: fetchSurgicalBeforeIPD?.[0]?.pk,
      reAssessmentSurgicalAfterIPD: fetchSurgicalAfterIPD?.[0]?.pk,
      reAssessmentUrinaryIPD: fetchUrinaryIPD?.[0]?.pk,

      sleepingPerDay: "",
      sleepingEnough: "",
      sleepingProblem: "",
      sleepingProblemRemark: "",
      sleepPill: "",
      sleepPillRemark: "",

      riskFall: "",
      riskFallRemark: "",
      nursingCare: "",

      painStatus: "",
      assessPainLevel: "",
      painLevel: "",
      painArea: "",
      painNature: "",
      sensesNursingCare: "",
      riskFallQuestion: {
        riskFallAge: patientAgeResult?.value,
        riskFallAgeScore: patientAgeResult?.score,
      },
      painData: {
        saveData: formatDateToDay,
        saveTime: timeToDay,
      },
      riskFallData: {
        saveData: formatDateToDay,
        saveTime: timeToDay,
      },
      sleepData: {
        saveData: formatDateToDay,
        saveTime: timeToDay,
      },
      venousData: {
        saveData: formatDateToDay,
        saveTime: timeToDay,
      },
      surgicalBeforeData: {
        type: "SURGICAL_BEFORE",
        saveData: formatDateToDay,
        saveTime: timeToDay,
      },
      surgicalAfterData: {
        type: "SURGICAL_AFTER",
        saveData: formatDateToDay,
        saveTime: timeToDay,
      },
      urinaryData: {
        saveData: formatDateToDay,
        saveTime: timeToDay,
      },
      // history
      painDataHistory: {
        startDate: sevenDaysAgo,
        endDate: formatDateToDay,
        data: [],
      },
      riskFallDataHistory: {
        startDate: sevenDaysAgo,
        endDate: formatDateToDay,
        data: [],
      },
      sleepDataHistory: {
        startDate: sevenDaysAgo,
        endDate: formatDateToDay,
        data: [],
      },
      venousDataHistory: {
        startDate: sevenDaysAgo,
        endDate: formatDateToDay,
        data: [],
      },
      surgicalDataHistory: {
        startDate: sevenDaysAgo,
        endDate: formatDateToDay,
        data: [],
      },
      urinaryDataHistory: {
        startDate: sevenDaysAgo,
        endDate: formatDateToDay,
        data: [],
      },
    },
    reAssessmentOptions: {
      painRiskFallOptions: [
        { key: "1", value: "ความเสี่ยงต่ำ", text: "ความเสี่ยงต่ำ" },
        { key: "2", value: "ความเสี่ยงสูง", text: "ความเสี่ยงสูง" },
      ],
      painNursingCareOptions: fallRiskNursingOptions,
      sensesNursingCareOptions: nursingCareOptions,
      painLevel: painLevelOptions,
      painFrequency: frequencyOptions,
      painNonMedicalIntervention: nonMedicalInterventionOptions,
      painSedatScore: sedatScoreOptions,
      painSideEffect: sideEffectOptions,
      painAssessmentTool: assessmentToolOptions,
      riskFallAgeRange: riskFallAgeRange,
      riskFallHistory: historyOfFallOptions,
      riskFallMental: mentalStatusOptions,
      riskFallSensory: sensoryOptions,
      riskFallDeficit: deficitOptions,
      riskFallElimination: eliminationOptions,
      riskFallProblem: problemOptions,
      riskFallMedication: medicationOptions,
      riskFallMobily: mobilyOptions,
      riskFallResult: riskFallResultOptions,
    },
  });

  AddOrEdit(controller, { ...params, action: "getDataHistory" });
};

export const AddOrEdit: Handler = async (controller, params) => {
  const state = controller.getState();
  const dataReAssessment = state.ReAssessmentSequence;

  if (SAVE_LIST.includes(params?.action)) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    let formCode = "";
    let formName = "";
    let reAssessmenID = "";
    let newData = {};
    let clearData = {};
    const toDay = moment();
    const formatDateToDay = formatDate(toDay);
    const timeToDay = toDay.format("HH:mm");

    if (params.action === "savePain") {
      // Shift Requires Check
      if (!dataReAssessment?.painData?.saveShift) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [`${params.card}`]: "กรุณาเลือก Shift ก่อนบันทึก",
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "ERROR",
          },
        })
        return;
      }

      formCode = "CardReAssessmentPainIPD";
      formName = "ประเมินความเจ็บปวดผู้ป่วยหลังออกจากห้องตรวจ (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentPainID;

      //* Legacy Data
      // newData = {
      //   painStatus: dataReAssessment?.painStatus,
      //   assessPainLevel: dataReAssessment?.assessPainLevel,
      //   painLevel: dataReAssessment?.painLevel,
      //   painArea: dataReAssessment?.painArea,
      //   painNature: dataReAssessment?.painNature,
      //   sensesNursingCare: dataReAssessment?.sensesNursingCare,
      //   saveData: dataReAssessment?.painData?.saveData || formatDateToDay,
      //   saveTime: dataReAssessment?.painData?.saveTime || timeToDay,
      //   editUserName: state.django.user.full_name,
      // };

      newData = {
        painStatus: dataReAssessment?.painQuestion?.painStatus,
        painAssessmentTool: dataReAssessment?.painQuestion?.painAssessmentTool,
        painScore: dataReAssessment?.painQuestion?.painScore,
        painLocation: dataReAssessment?.painQuestion?.painLocation,
        painDurationMinute: dataReAssessment?.painQuestion?.painDurationMinute,
        painDurationSecond: dataReAssessment?.painQuestion?.painDurationSecond,
        painCharacteristic: dataReAssessment?.painQuestion?.painCharacteristic,
        painCharacteristicNote: dataReAssessment?.painQuestion?.painCharacteristicNote,
        painFrequency: dataReAssessment?.painQuestion?.painFrequency,
        painInformedPhysician: dataReAssessment?.painQuestion?.painInformedPhysician,
        painInformedPhysicianNote: dataReAssessment?.painQuestion?.painInformedPhysicianNote,
        painNonMidicalIntervention: dataReAssessment?.painQuestion?.painNonMidicalIntervention,
        painMedicationGive: dataReAssessment?.painQuestion?.painMedicationGive,
        painMedicationGiveNote: dataReAssessment?.painQuestion?.painMedicationGiveNote,
        painMedicationGiveTime: dataReAssessment?.painQuestion?.painMedicationGiveTime,
        painSedatScoreBefore: dataReAssessment?.painQuestion?.painSedatScoreBefore,
        painSedatScoreAfter: dataReAssessment?.painQuestion?.painSedatScoreAfter,
        painScoreAfterIntervention: dataReAssessment?.painQuestion?.painScoreAfterIntervention,
        painScoreAfterInterventionScore: dataReAssessment?.painQuestion?.painScoreAfterInterventionScore,
        painScoreAfterInterventionTime: dataReAssessment?.painQuestion?.painScoreAfterInterventionTime,
        painSideEffect: dataReAssessment?.painQuestion?.painSideEffect,
        painSideEffectNote: dataReAssessment?.painQuestion?.painSideEffectNote,
        saveShift: dataReAssessment?.painData?.saveShift,
        saveData: dataReAssessment?.painData?.saveData || formatDateToDay,
        saveTime: dataReAssessment?.painData?.saveTime || timeToDay,
        editUserName: state.django.user.full_name,
      };

      clearData = {
        ...dataReAssessment,
        painData: {
          saveData: formatDateToDay,
          saveTime: timeToDay,
        },
        // painStatus: "",
        // assessPainLevel: "",
        // painLevel: "",
        // painArea: "",
        // painNature: "",
        // sensesNursingCare: "",
        painQuestion: {
          painStatus: null,
          painAssessmentTool: null,
          painScore: null,
          painLocation: null,
          painDurationMinute: null,
          painDurationSecond: null,
          painCharacteristic: null,
          painCharacteristicNote: null,
          painFrequency: null,
          painInformedPhysician: null,
          painInformedPhysicianNote: null,
          painNonMidicalIntervention: null,
          painMedicationGive: null,
          painMedicationGiveNote: null,
          painMedicationGiveTime: null,
          painSedatScoreBefore: null,
          painSedatScoreAfter: null,
          painScoreAfterIntervention: null,
          painScoreAfterInterventionScore: null,
          painScoreAfterInterventionTime: null,
          painSideEffect: null,
          painSideEffectNote: null,
        },
      };
    } else if (params.action === "saveRiskFall") {
      // Shift Requires Check
      if (!dataReAssessment?.riskFallData?.saveShift) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [`${params.card}`]: "กรุณาเลือก Shift ก่อนบันทึก",
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "ERROR",
          },
        })
        return;
      }

      formCode = "CardReAssessmentRiskFallIPD";
      formName = "ประเมินความเสี่ยงต่อการพลัดตกหกล้มผู้ป่วยหลังออกจากห้องตรวจ (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentRiskFallIPD;

      // get Patient Age
      const patientAgeResult = await getPatientAgeRiskFall(state?.selectedPatient?.age);

      newData = {
        // riskFall: dataReAssessment?.riskFall,
        // riskFallRemark: dataReAssessment?.riskFallRemark,
        // nursingCare: dataReAssessment?.nursingCare,
        riskFallScore: dataReAssessment?.riskFallQuestion?.riskFallScore,
        riskFallAge: dataReAssessment?.riskFallQuestion?.riskFallAge,
        riskFallAgeScore: dataReAssessment?.riskFallQuestion?.riskFallAgeScore,
        riskFallHistory: dataReAssessment?.riskFallQuestion?.riskFallHistory,
        riskFallHistoryScore: dataReAssessment?.riskFallQuestion?.riskFallHistoryScore,
        riskFallMental: dataReAssessment?.riskFallQuestion?.riskFallMental,
        riskFallMentalScore: dataReAssessment?.riskFallQuestion?.riskFallMentalScore,
        riskFallSensory: dataReAssessment?.riskFallQuestion?.riskFallSensory,
        riskFallSensoryDeficit: dataReAssessment?.riskFallQuestion?.riskFallSensoryDeficit,
        riskFallSensoryScore: dataReAssessment?.riskFallQuestion?.riskFallSensoryScore,
        riskFallElimination: dataReAssessment?.riskFallQuestion?.riskFallElimination,
        riskFallEliminationProblem: dataReAssessment?.riskFallQuestion?.riskFallEliminationProblem,
        riskFallEliminationScore: dataReAssessment?.riskFallQuestion?.riskFallEliminationScore,
        riskFallMedication: dataReAssessment?.riskFallQuestion?.riskFallMedication,
        riskFallMedicationScore: dataReAssessment?.riskFallQuestion?.riskFallMedicationScore,
        riskFallMobily: dataReAssessment?.riskFallQuestion?.riskFallMobily,
        riskFallMobilyScore: dataReAssessment?.riskFallQuestion?.riskFallMobilyScore,
        riskFallTotalScore: dataReAssessment?.riskFallQuestion?.riskFallTotalScore,
        riskFallTotalPrecaution: dataReAssessment?.riskFallQuestion?.riskFallTotalPrecaution,
        saveShift: dataReAssessment?.riskFallData?.saveShift,
        saveData: dataReAssessment?.riskFallData?.saveData || formatDateToDay,
        saveTime: dataReAssessment?.riskFallData?.saveTime || timeToDay,
        editUserName: state.django.user.full_name,
      };

      clearData = {
        ...dataReAssessment,
        riskFallData: {
          saveData: formatDateToDay,
          saveTime: timeToDay,
        },
        riskFallQuestion: {
          riskFallScore: null,
          riskFallAge: patientAgeResult?.value,
          riskFallAgeScore: patientAgeResult?.score,
          riskFallHistory: null,
          riskFallHistoryScore: null,
          riskFallMental: null,
          riskFallMentalScore: null,
          riskFallSensory: null,
          riskFallSensoryDeficit: null,
          riskFallSensoryScore: null,
          riskFallElimination: null,
          riskFallEliminationProblem: null,
          riskFallEliminationScore: null,
          riskFallMedication: null,
          riskFallMedicationScore: null,
          riskFallMobily: null,
          riskFallMobilyScore: null,
          riskFallTotalScore: null,
          riskFallTotalPrecaution: null,
        },
        // riskFall: "",
        // riskFallRemark: "",
        // nursingCare: "",
      };
    } else if (params.action === "saveSleep") {
      // Shift Requires Check
      if (!dataReAssessment?.sleepData?.saveShift) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [`${params.card}`]: "กรุณาเลือก Shift ก่อนบันทึก",
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "ERROR",
          },
        })
        return;
      }

      formCode = "CardReAssessmentSleepIPD";
      formName = "ประเมินการพักผ่อนนอนหลับผู้ป่วยหลังออกจากห้องตรวจ (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentSleepIPD;

      newData = {
        sleepingPerDay: dataReAssessment?.sleepingPerDay,
        sleepingEnough: dataReAssessment?.sleepingEnough,
        sleepingProblem: dataReAssessment?.sleepingProblem,
        sleepingProblemRemark: dataReAssessment?.sleepingProblemRemark,
        sleepPill: dataReAssessment?.sleepPill,
        sleepPillRemark: dataReAssessment?.sleepPillRemark,
        saveShift: dataReAssessment?.sleepData?.saveShift,
        saveData: dataReAssessment?.sleepData?.saveData || formatDateToDay,
        saveTime: dataReAssessment?.sleepData?.saveTime || timeToDay,
        editUserName: state.django.user.full_name,
      };

      clearData = {
        ...dataReAssessment,
        sleepData: {
          saveData: formatDateToDay,
          saveTime: timeToDay,
        },
        sleepingPerDay: "",
        sleepingEnough: "",
        sleepingProblem: "",
        sleepingProblemRemark: "",
        sleepPill: "",
        sleepPillRemark: "",
      };
    } else if (params.action === "saveVenous") {
      // Shift Require Check
      if (!dataReAssessment?.venousData?.saveShift) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [`${params.card}`]: "กรุณาเลือก Shift ก่อนบันทึก",
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "ERROR",
          },
        })
        return;
      }

      formCode = "CardReAssessmentVenousIPD";
      formName = "แบบบันทึกข้อมูลการเฝ้าระวังหลอดเลือดดำอักเสบ (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentVenousIPD;

      newData = {
        venousQuestOne: dataReAssessment?.venousQuestion?.venousQuestOne || "NA",
        venousQuestTwo: dataReAssessment?.venousQuestion?.venousQuestTwo || "NA",
        venousQuestThree: dataReAssessment?.venousQuestion?.venousQuestThree || "NA",
        venousQuestFour: dataReAssessment?.venousQuestion?.venousQuestFour || "NA",
        venousQuestFive: dataReAssessment?.venousQuestion?.venousQuestFive || "NA",
        venousQuestSix: dataReAssessment?.venousQuestion?.venousQuestSix || "NA",
        venousQuestSeven: dataReAssessment?.venousQuestion?.venousQuestSeven || "NA",
        venousQuestEight: dataReAssessment?.venousQuestion?.venousQuestEight || "NA",
        saveShift: dataReAssessment?.venousData?.saveShift,
        saveData: dataReAssessment?.venousData?.saveData || formatDateToDay,
        saveTime: dataReAssessment?.venousData?.saveTime || timeToDay,
        editUserName: state.django.user.full_name,
      };

      clearData = {
        ...dataReAssessment,
        venousData: {
          saveData: formatDateToDay,
          saveTime: timeToDay,
        },
        venousQuestion: {
          venousQuestOne: null,
          venousQuestTwo: null,
          venousQuestThree: null,
          venousQuestFour: null,
          venousQuestFive: null,
          venousQuestSix: null,
          venousQuestSeven: null,
          venousQuestEight: null,
        }
      };
    } else if (params.action === "saveSurgicalBefore") {
      // Shift Requires Check
      if (!dataReAssessment?.surgicalBeforeData?.saveShift) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [`${params.card}`]: "กรุณาเลือก Shift ก่อนบันทึก",
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "ERROR",
          },
        })
        return;
      }

      formCode = "CardReAssessmentSurgicalBeforeIPD";
      formName = "แบบประเมินการปฏิบัติการป้องกันการติดเชื้อตำแหน่งแผลผ่าตัด (ก่อน) (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentSurgicalBeforeIPD;

      newData = {
        surgicalBeforeQuestOne: dataReAssessment?.surgicalBeforeQuestion?.surgicalBeforeQuestOne || "NA",
        surgicalBeforeQuestTwo: dataReAssessment?.surgicalBeforeQuestion?.surgicalBeforeQuestTwo || "NA",
        surgicalBeforeQuestThree: dataReAssessment?.surgicalBeforeQuestion?.surgicalBeforeQuestThree || "NA",
        surgicalBeforeQuestFour: dataReAssessment?.surgicalBeforeQuestion?.surgicalBeforeQuestFour || "NA",
        type: dataReAssessment?.surgicalBeforeData?.type || "SURGICAL_BEFORE",
        saveShift: dataReAssessment?.surgicalBeforeData?.saveShift,
        saveData: dataReAssessment?.surgicalBeforeData?.saveData || formatDateToDay,
        saveTime: dataReAssessment?.surgicalBeforeData?.saveTime || timeToDay,
        editUserName: state.django.user.full_name,
      };

      clearData = {
        ...dataReAssessment,
        surgicalBeforeData: {
          type: "SURGICAL_BEFORE",
          saveData: formatDateToDay,
          saveTime: timeToDay,
        },
        surgicalBeforeQuestion: {
          surgicalBeforeQuestOne: null,
          surgicalBeforeQuestTwo: null,
          surgicalBeforeQuestThree: null,
          surgicalBeforeQuestFour: null,
        }
      };
    } else if (params.action === "saveSurgicalAfter") {
      // Shift Requires Check
      if (!dataReAssessment?.surgicalAfterData?.saveShift) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [`${params.card}`]: "กรุณาเลือก Shift ก่อนบันทึก",
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "ERROR",
          },
        })
        return;
      }

      formCode = "CardReAssessmentSurgicalAfterIPD";
      formName = "แบบประเมินการปฏิบัติการป้องกันการติดเชื้อตำแหน่งแผลผ่าตัด (หลัง) (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentSurgicalAfterIPD;

      newData = {
        surgicalAfterQuestOne: dataReAssessment?.surgicalAfterQuestion?.surgicalAfterQuestOne || "NA",
        surgicalAfterQuestTwo: dataReAssessment?.surgicalAfterQuestion?.surgicalAfterQuestTwo || "NA",
        surgicalAfterQuestThree: dataReAssessment?.surgicalAfterQuestion?.surgicalAfterQuestThree || "NA",
        type: dataReAssessment?.surgicalAfterData?.type || 'SURGICAL_AFTER',
        saveShift: dataReAssessment?.surgicalAfterData?.saveShift,
        saveData: dataReAssessment?.surgicalAfterData?.saveData || formatDateToDay,
        saveTime: dataReAssessment?.surgicalAfterData?.saveTime || timeToDay,
        editUserName: state.django.user.full_name,
      };

      clearData = {
        ...dataReAssessment,
        surgicalAfterData: {
          type: "SURGICAL_AFTER",
          saveData: formatDateToDay,
          saveTime: timeToDay,
        },
        surgicalAfterQuestion: {
          surgicalAfterQuestOne: null,
          surgicalAfterQuestTwo: null,
          surgicalAfterQuestThree: null,
        }
      };
    } else if (params.action === "saveUrinary") {
      // Shift Requires Check
      if (!dataReAssessment?.urinaryData?.saveShift) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [`${params.card}`]: "กรุณาเลือก Shift ก่อนบันทึก",
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "ERROR",
          },
        })
        return;
      }

      formCode = "CardReAssessmentUrinaryIPD";
      formName = "แบบบันทึกการป้องกันการเกิดการติดเชื้อทางเดินปัสสาวะจากการใส่สายปัสสาวะ : CAUTI Bundles (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentUrinaryIPD;

      newData = {
        urinaryQuestOne: dataReAssessment?.urinaryQuestion?.urinaryQuestOne || "NA",
        urinaryQuestTwo: dataReAssessment?.urinaryQuestion?.urinaryQuestTwo || "NA",
        urinaryQuestThree: dataReAssessment?.urinaryQuestion?.urinaryQuestThree || "NA",
        urinaryQuestFour: dataReAssessment?.urinaryQuestion?.urinaryQuestFour || "NA",
        urinaryQuestFourSubOne: dataReAssessment?.urinaryQuestion?.urinaryQuestFourSubOne || "NA",
        urinaryQuestFourSubTwo: dataReAssessment?.urinaryQuestion?.urinaryQuestFourSubTwo || "NA",
        urinaryQuestFourSubThree: dataReAssessment?.urinaryQuestion?.urinaryQuestFourSubThree || "NA",
        urinaryQuestFourSubFour: dataReAssessment?.urinaryQuestion?.urinaryQuestFourSubFour || "NA",
        urinaryQuestFourSubFive: dataReAssessment?.urinaryQuestion?.urinaryQuestFourSubFive || "NA",
        saveShift: dataReAssessment?.urinaryData?.saveShift,
        saveData: dataReAssessment?.urinaryData?.saveData || formatDateToDay,
        saveTime: dataReAssessment?.urinaryData?.saveTime || timeToDay,
        editUserName: state.django.user.full_name,
      };

      clearData = {
        ...dataReAssessment,
        urinaryData: {
          saveData: formatDateToDay,
          saveTime: timeToDay,
        },
        urinaryQuestion: {
          urinaryQuestOne: null,
          urinaryQuestTwo: null,
          urinaryQuestThree: null,
          urinaryQuestFour: null,
          urinaryQuestFourSubOne: null,
          urinaryQuestFourSubTwo: null,
          urinaryQuestFourSubThree: null,
          urinaryQuestFourSubFour: null,
          urinaryQuestFourSubFive: null,
        }
      };
    }

    const [respFetch, errFetch, netwFetch] = await FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: params.selectedEncounter || state.selectedEncounter?.id,
        form_code: formCode,
        form_version: 1.0,
      },
      extra: { division: controller.data.division },
    });

    let item = [];
    if (respFetch?.data?.item) {
      item = [...respFetch?.data?.item, newData];
    } else {
      item = [newData];
    }

    let data: any = {
      form_code: formCode,
      form_name: formName,
      form_version: 1.0,
      encounter: params.selectedEncounter || state.selectedEncounter?.id,
      action: "SAVE",
      data: { item: item },
    };

    if (reAssessmenID) {
      const [respAction, errAction, netwAction] = await FormDataDetail.update({
        pk: reAssessmenID,
        data: data,
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

      if (errAction) {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "SUCCESS",
          },
          ReAssessmentSequence: clearData,
        });
      }
    } else {
      const [respAction, errAction, netwAction] = await FormDataList.create({
        data: data,
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

      if (errAction) {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "SUCCESS",
          },
          ReAssessmentSequence: clearData,
        });

        // AddOrEdit(controller, { ...params, action: "getDataHistory" });
        Start(controller, { ...params }); //* เมื่อบันทึกแล้วไปลบใน History จะลบไม่ได้ เพราะไม่ได้อัพเดต formdata
      }
    }
  } else if (params?.action === "getDataHistory") {
    const [
        fetchPain,
        fetchRiskFall,
        fetchSleep,
        fetchVenous,
        fetchSurgicalBefore,
        fetchSurgicalAfter,
        fetchUrinary
      ] = await Promise.allSettled([
      FormDataDetailByEncounter.retrieve({
        pk: params.selectedEncounter || state?.selectedEncounter.pk,
        params: { form_data_code: "CardReAssessmentPainIPD" },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      }),
      FormDataDetailByEncounter.retrieve({
        pk: params.selectedEncounter || state?.selectedEncounter.pk,
        params: { form_data_code: "CardReAssessmentRiskFallIPD" },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      }),
      FormDataDetailByEncounter.retrieve({
        pk: params.selectedEncounter || state?.selectedEncounter.pk,
        params: { form_data_code: "CardReAssessmentSleepIPD" },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      }),
      FormDataDetailByEncounter.retrieve({
        pk: params.selectedEncounter || state?.selectedEncounter.pk,
        params: { form_data_code: "CardReAssessmentVenousIPD" },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      }),
      FormDataDetailByEncounter.retrieve({
        pk: params.selectedEncounter || state?.selectedEncounter.pk,
        params: { form_data_code: "CardReAssessmentSurgicalBeforeIPD" },
        apiToken: controller.apiToken,
        extra: {
          divivsion: controller.data.division,
        },
      }),
      FormDataDetailByEncounter.retrieve({
        pk: params.selectedEncounter || state?.selectedEncounter.pk,
        params: { form_data_code: "CardReAssessmentSurgicalAfterIPD" },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      }),
      FormDataDetailByEncounter.retrieve({
        pk: params.selectedEncounter || state?.selectedEncounter.pk,
        params: { form_data_code: "CardReAssessmentUrinaryIPD" },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      })
    ]);

    // Promise.allSettled return {status, value}
    let painHistory = fetchPain.value;
    let riskFallHistory = fetchRiskFall.value;
    let sleepHistory = fetchSleep.value;
    let venousHistory = fetchVenous.value;
    let surgicalBeforeHistory = fetchSurgicalBefore.value;
    let surgicalAfterHistory = fetchSurgicalAfter.value;
    let urinaryHistory = fetchUrinary.value;

    //* issue 72223
    let surgicalDataHistory = [
      ...(surgicalBeforeHistory?.[0]?.data?.item || []),
      ...(surgicalAfterHistory?.[0]?.data?.item || [])
    ];

    const sortedSurgicalHistory = sortedDataByDateTime(surgicalDataHistory) || surgicalDataHistory;

    controller.setState({
      ReAssessmentSequence: {
        ...state.ReAssessmentSequence,
        painDataHistory: {
          ...state.ReAssessmentSequence?.painDataHistory,
          data: painHistory?.[0]?.data?.item || [],
        },
        riskFallDataHistory: {
          ...state.ReAssessmentSequence?.riskFallDataHistory,
          data: riskFallHistory?.[0]?.data?.item || [],
        },
        sleepDataHistory: {
          ...state.ReAssessmentSequence?.sleepDataHistory,
          data: sleepHistory?.[0]?.data?.item || [],
        },
        venousDataHistory: {
          ...state.ReAssessmentSequence?.venousDataHistory,
          data: venousHistory?.[0]?.data?.item || [],
        },
        surgicalDataHistory: {
          ...state.ReAssessmentSequence?.venousDataHistory,
          data: sortedSurgicalHistory || [],
        },
        urinaryDataHistory: {
          ...state.ReAssessmentSequence?.urinaryDataHistory,
          data: urinaryHistory?.[0]?.data?.item || [],
        },
      },
    });
  }
  if (params?.action === "deleteDataHistory") {
    let formCodeType = "";
    let formNameType = "";
    let reAssessmenID = "";

    if (params?.formCode === "PAIN") {
      formCodeType = "CardReAssessmentPainIPD";
      formNameType = "ประเมินความเจ็บปวดผู้ป่วยหลังออกจากห้องตรวจ (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentPainID;
    } else if (params?.formCode === "RISK_FALL") {
      formCodeType = "CardReAssessmentRiskFallIPD";
      formNameType = "ประเมินความเสี่ยงต่อการพลัดตกหกล้มผู้ป่วยหลังออกจากห้องตรวจ (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentRiskFallIPD;
    } else if (params?.formCode === "SLEEP") {
      formCodeType = "CardReAssessmentSleepIPD";
      formNameType = "ประเมินการพักผ่อนนอนหลับผู้ป่วยหลังออกจากห้องตรวจ (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentSleepIPD;
    } else if (params?.formCode === "VENOUS") {
      formCodeType = "CardReAssessmentVenousIPD";
      formNameType = "แบบบันทึกข้อมูลการเฝ้าระวังหลอดเลือดดำอักเสบ (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentVenousIPD;
    } else if (params?.formCode === "SURGICAL") {
      const surgicalFormType: any = {
        SURGICAL_BEFORE: {
          formCodeType: "CardReAssessmentSurgicalBeforeIPD",
          formNameType: "แบบประเมินการปฏิบัติการป้องกันการติดเชื้อตำแหน่งแผลผ่าตัด (ก่อน) (IPD)",
          reAssessmentKey: "reAssessmentSurgicalBeforeIPD"
        },
        SURGICAL_AFTER: {
          formCodeType: "CardReAssessmentSurgicalAfterIPD",
          formNameType: "แบบประเมินการปฏิบัติการป้องกันการติดเชื้อตำแหน่งแผลผ่าตัด (หลัง) (IPD)",
          reAssessmentKey: "reAssessmentSurgicalAfterIPD"
        }
      };

      if (!params?.specFormType) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params?.card]: "ไม่พบ Formdata ที่ต้องการลบ",
          },
        });
      }

      const selectedFormType = surgicalFormType[params.specFormType];
      if (selectedFormType) {
        formCodeType = selectedFormType.formCodeType;
        formNameType = selectedFormType.formNameType;
        reAssessmenID = dataReAssessment?.[selectedFormType.reAssessmentKey];
      } else {
        console.warn("ไม่พบ FormCode สำหรับลบข้อมูล ", selectedFormType);
      }
    } else if (params?.formCode === "URINARY") {
      formCodeType = "CardReAssessmentUrinaryIPD";
      formNameType = "แบบบันทึกการป้องกันการเกิดการติดเชื้อทางเดินปัสสาวะจากการใส่สายปัสสาวะ : CAUTI Bundles (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentUrinaryIPD;
    }

    const [resp, err, netw] = await FormDataDetail.update({
      pk: reAssessmenID,
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
      data: {
        form_code: formCodeType,
        form_name: formNameType,
        form_version: 1.0,
        encounter: params.selectedEncounter || state.selectedEncounter.id,
        action: "SAVE",
        data: params.data, // {item, remark}
      },
    });
    if (err) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params?.card]: err,
        },
      });
    } else {
      controller.setState({
        successMessage: { ...state.successMessage, [params?.card]: "Success." },
      });
      AddOrEdit(controller, { ...params, action: "getDataHistory" });
    }
  }

  if (!state.ReAssessmentSequence || !state.selectedEncounter || !params.selectedEncounter) {
    return;
  }
};

/**============================================
 **                  Utils
 *=============================================**/
const getPatientAgeRiskFall = async (age: number) => {
  if (!age || age < 15) {
    return null;
  }

  const result = riskFallAgeRange.find((range: any) => age >= range.min && age <= range.max);

  return result ? { score: result.score, value: result.value } : null;
};

const sortedDataByDateTime = (data: any[]) => {
  if(!Array.isArray(data)) {
    console.error("Re-Assesment Error: Data is not Array!");
    return;
  }

  return data.sort((a: any, b: any): number => {
    if (!a.saveData || !a.saveTime || !b.saveData || !b.saveTime) {
      console.warn("Re-Assessment Error: Missing saveData or saveTime in data!");
      return 0;
    }

    const dateTimeA = beStringToMoment(a.saveData).set({
      hour: parseInt(a.saveTime.split(":")[0], 10),
      minute: parseInt(a.saveTime.split(":")[1], 10),
    });

    const dateTimeB = beStringToMoment(b.saveData).set({
      hour: parseInt(b.saveTime.split(":")[0], 10),
      minute: parseInt(b.saveTime.split(":")[1], 10),
    });

    if (!dateTimeA.isValid() || !dateTimeB.isValid()) {
      console.error("Re-Assessment Error: Cannot Format Datetime from data!");
      return 0;
    }

    if (dateTimeA.isBefore(dateTimeB)) return -1;
    if (dateTimeA.isAfter(dateTimeB)) return 1;
    return 0;
  });
};