import WasmController from "react-lib/frameworks/WasmController";
import { State as MainState } from "HIS/MainHISInterface";

// APIs
import CoPhysicianList from "issara-sdk/apis/CoPhysicianList_core";

export type State = Partial<{
  loadingStatus?: any;
  errorMessage?: any;
  django?: any;

  AddCoPhysicianSequence: {
    sequenceIndex: string | null;

    coPhysician: any;
    coPhysicianList: any[];
  } | null;
}>;

type Picked = Partial<
  Pick<MainState, "errorMessage" | "successMessage" | "loadingStatus" | "buttonLoadCheck">
>;

export const StateInitial: State = {
  AddCoPhysicianSequence: {
    sequenceIndex: null,

    coPhysician: null,
    coPhysicianList: [],
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  user?: number;
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State & Picked, Event, Data>, params?: any) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  controller.setState({
    AddCoPhysicianSequence: {
      ...state.AddCoPhysicianSequence,
      sequenceIndex: "Action",
      coPhysician: null,
      coPhysicianList: params.coPhysicianList || [],
    },
  });
}

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params.action === "SAVE") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    const doctorList: any[] = state.AddCoPhysicianSequence?.coPhysicianList?.map((item: any) => {
      return item.id;
    }) || [];

    if (doctorList.length === 0) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: "กรุณาเลือกแพทย์ที่ต้องการ แล้วกด + เพื่อเพิ่ม",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
      return;
    }

    const [resp, err, netw] = await CoPhysicianList.create({
      apiToken: controller.apiToken,
      data: {
        encounter: params.encounter,
        doctor_list: doctorList,
      },
      extra: {
        division: controller.data.division,
        device: controller.data.device,
      },
    });
    if (err) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: err },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: null,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
      });
      params.onSuccess?.();
    }
  }
};
